<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper" :style=" 'background-image: url(' + imgUrl + '); background-size: cover; background-repeat: no-repeat;' ">
    <b-link class="brand-logo" href="/">
      <vuexy-logo/>
      <b-img class="ml-1" src="@/assets/images/logo/logo-2.png" fluid alt="Logo Text" style="height: 40px"/>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Contact
        </h2>
        <p class="mb-2">
          Let's chat shit.
        </p>
        <b-button
            class="login-button"
            variant="info"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            href="https://twitter.com/BetaFloof_"
        >
          <feather-icon
              icon="TwitterIcon"
              class="mr-50"
          />
          @BetaFloof_
        </b-button>
        <p></p>
        <b-button
            class="login-button"
            variant="success"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            href="mailto:betafloof@gmail.com"
        >
          <feather-icon
              icon="MailIcon"
              class="mr-50"
          />
          Mail
        </b-button>
        <p></p>
        <b-button
            class="login-button mb-1"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            href="/"
        >
          <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
          />
          Go back
        </b-button>

      </div>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BForm, BFormInput, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import AppFooter from "@core/layouts/components/AppFooter.vue";

export default {
  components: {
    AppFooter,
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/contact.png')
    }
  },
  computed: {
    imgUrl() {
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-misc.scss';
</style>
